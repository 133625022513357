/* Header steps */
.steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;
}

.steps-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.desktop-brand {
    display: none;
}

.desktop-brand-mtek {
    display: none;
}

.brand-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-text {
    font-weight: bold;
    font-size: 10.9922px;
    line-height: 13px;
    letter-spacing: -0.02em;
    color: #30345E;
    margin-right: 5px;
}

.brand-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.playstore-container img {
    width: 75px;
}

.playstore-container {
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.mob-logos-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.step-indicator {
    font-size: 1rem;
    line-height: 24px;
    color: #000000;
    font-family: 'Roboto-Bold';
}

.step-1 {
    margin-top: 30px;
}

.desktop-nav {
    display: none;
}

.marked {
    color: #36C2D7;
}

.brand-footer {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.auth-mgt-wrapper {
    padding: 8px;
    border-radius: 7px;
    border: 0.733333px solid #4060AF;
}

/* Desktop View */
@media only screen and (min-width: 600px) {

    /* universal header in the motion div */
    .steps-container {
        width: 70%;
    }

    /* End universal header in the motion div */
    /* Header */
    .desktop-brand {
        display: block;
        position: absolute;
        top: 3%;
        left: 2%;
    }

    .desktop-brand-mtek {
        display: block;
        position: absolute;
        top: 3%;
        right: 2%;
    }

    .desktop-brand-mtek a {
        margin-bottom: 20px;
    }

    .brand-container {
        justify-content: left;
        /* margin-left: -20%; */
    }

    .brand-step1 {
        justify-content: center;
        margin-left: 0;
    }

    .playstore-container {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }

    .playstore-container img {
        width: 80px;
    }

    .mob-logos-container {
        display: none;
    }

    .step-indicator {
        display: none;
    }

    .desktop-nav {
        display: block;
        background-image: url("../../../assets/images/lined.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 100%;
        background-position-y: 22px;
        background-color: transparent;
        margin-top: 20px;
        margin-bottom: 40px;
        width: 100%;
    }

    .nav-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        position: relative;
    }

    .nav-step-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #A5A5A5;
    }

    .marked {
        color: #30345E;
    }

    .nav-circle {
        width: 43px;
        height: 43px;
        background: #FBFBFB;
        border: 0.826923px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-circle span {
        font-weight: 600;
    }

    .active-grow {
        width: 53px;
        height: 53px;
        background: #FBFBFB;
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 4px 20px rgba(0, 168, 87, 0.2);
        font-size: 28px;
        margin-top: -6px;
    }

    .marked>.step-details {
        font-size: 14px;
        font-family: "Roboto-Medium";
        line-height: 15px;
        text-align: center;
        color: #E55C3E;
    }

    .brand-step1 {
        justify-content: center;
        margin-left: 0;
    }

    /* End Header View */
}

/* End Desktop View */
/* end header steps */