.input-file{
    margin-right: 10px;
}

.upload-status-container{
    margin-top: 5px;
    margin-right: 5px;
  }

  .upload-img-container{
    position: relative;
  }
  
  .upload-img-container > .uploaded-img{
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
  .uploaded-file-status{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  }
  
  .tick-icon-i{
    width: 25px;
    height: 25px;
    position:absolute;
    margin-top: -5px;
    margin-top: -5px;
  }
  
   .tick-icon{
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .error-msg{
    margin-top: 8px;
    background: #FEEFEF;
    border-radius: 8px;
    padding: 6px 16px;
    display: flex;
  }
  
  .error-msg img{
    margin-right: 4px;
  }
  
  .error-msg span{
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #DA1414;
  }
  