
:root {
  --primary-color: #e55c3e;
  --secondary-color: #a5a5a5
}
/* Get started stylings */
.get-started {
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.travel-instruction {
  font-size: 28px;
  font-family: "Roboto-Bold";
  color: #000000;
  margin-bottom: 2rem;
}

.get-started-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1rem;
}

.travel-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  margin: 0;
}

.travel-button {
  border: 1px solid var(--primary-color);
  font-family: "Roboto-Regular";
  /* font-size: 20px; */
  color: #000000;
  min-width: auto;
  height: 61px;
  border-radius: 15px;
  margin-top: 3rem;
}

.travel-button:focus,
.travel-button:active,
.travel-button:hover {
  background-color: var(--primary-color);
  color: #ffff;
}

span {
  text-align: center;
}

.simple {
  font-size: 28.27px;
  font-weight: bold;
  font-family: "Roboto-Bold";
  margin-bottom: 1.7rem;
  color: var(--primary-color);
  align-items: center;
}

.simple-vehicle-details {
  color: #30345e;
  margin-bottom: 1.7rem;
  align-items: center;
}

.simple-sticker {
  color: var(--primary-color);
}

button {
  width: 108px;
  height: 54px;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  padding: 8px;
  background-color: transparent;
  border-radius: 8px;
  margin: 5px;
  font-family: "Roboto-Regular";
  font-size: 14px;
}

.button-get-started {
  border-radius: 20px;
  outline: none;
  border: none;
  color: white;
  background-color: var(--primary-color);
  width: 296px;
  height: 54px;
  font-family: "Roboto-Bold";
  font-size: 16px;
  margin: 2.5rem auto;
}

@media only screen and (min-width: 600px) {
  .get-started {
    width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .get-started-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .travel-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
  }

  .travel-button {
    border: 1px solid var(--primary-color);
    font-family: "Roboto-Regular";
    font-size: 18px;
    color: #000000;
    width: 272px;
    height: 61px;
    border-radius: 15px;
  }

  .travel-button:focus,
  .travel-button:active,
  .travel-button:hover {
    background-color: var(--primary-color);
    color: #ffff;
  }

  button {
    width: 208px;
    height: 54px;
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    padding: 8px;
    background-color: transparent;
    border-radius: 8px;
    margin: 5px;
    font-family: "Roboto-Regular";
    font-size: 14px;
  }

  .travel-options {
    width: 65%;
  }
}

.view-policies {
  cursor: pointer;
  font-family: "Roboto-Bold";
}

/* End get started */

/* Travel details ( individual & group ) */

.travel-details-title {
  font-family: "Roboto-Bold";
  font-size: 28.27px;
  color: #000;
}

.travel-container {
  width: 89%;
  margin: 0 auto;
}

.span-title {
  color: var(--primary-color);
}

input,
select {
  background: #ffffff;
  border: 1px solid #dadce0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  width: 50%;
}

.text-left-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #858887;
}

.ky-input-container input[type="text"],
input[type="date"] {
  background: #f6f8fb;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 100%;
  height: 47px;
}

.ky-input-wrapper {
  margin-bottom: 0.6rem;
}

.ky-input-wrapper-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.individual {
  margin-right: 3rem;
  display: flex;
}

.group {
  display: flex;
}

.ky-individual {
  margin-left: 0.8rem;
}

.ky-group {
  margin-left: 0.8rem;
}

.ky-input-wrapper-type input[type="radio"]:checked ~ * {
  color: var(--primary-color) !important;
}

.disabled-section {
  pointer-events: none;
  opacity: 0.3;
}

.add {
  font-size: 1rem;
  font-family: "Roboto-Medium";
  color: #858887;
  text-decoration: underline;
  margin-top: 2rem;
  cursor: pointer;
}

.countries {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.countries-selection {
  border: 1px solid var(--primary-color);
  width: 104px;
  border-radius: 5px;
  height: 47px;
  text-align: center;
  margin: auto 0;
  font-family: "Roboto-Medium";
  font-size: 18px;
}

.countries-selection:hover,
.countries-selection:focus,
.countries-selection:active {
  background-color: var(--primary-color);
  color: #fff;
}

.isOption-active {
  background-color: var(--primary-color);
  color: #fff;
}

.group-countries {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}

.pointer-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.next-btn {
  width: auto;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 1rem;
  font-family: "Roboto-Bold";
  border-radius: 5px;
  height: auto;
  border: none;
}

.next-btn svg {
  color: #fff;
  border-color: white;
}

.reason {
  width: 90%;
}

.add-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 116px;
  border: 1px solid #000;
  border-radius: 20px;
}

.add-member:hover,
.add-member:focus {
  cursor: pointer;
}

.add-member-text {
  font-family: "Roboto-Medium";
  font-size: 11px;
  margin-top: 1rem;
}

.member-card {
  width: 132px;
  border-radius: 15px;
  height: 111px;
  border: 1p solid var(--primary-color);
  margin-top: 1rem;
}

.avatar {
  margin-top: 1rem;
  padding: 8px 8px 0 8px;
}

.card-text {
  padding: 8px;
}

.member-text {
  font-family: "Roboto-Medium";
  font-size: 12px;
}

.members-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(87px, auto));
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}

.group-members {
  border: 1px solid var(--primary-color);
  width: 102px;
  border-radius: 10px;
  height: 96px;
}

.group-member-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.group-members-content-title,
.group-members-content-age {
  font-family: "Roboto-Medium";
  font-size: 12px;
  color: #858887;
}

.country-travel {
  pointer-events: none;
}

@media only screen and (min-width: 610px) {
  .travel-container {
    width: 63%;
    margin: 0 auto;
  }

  .ky-input-container input[type="text"],
  .ky-input-container input[type="date"] {
    width: 90%;
  }

  input[type="text"] {
    width: 90% !important;
  }

  .ky-inputs-container-1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    /* margin: 0 auto; */
  }

  .ky-inputs-inner-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .ky-input-wrapper {
    width: 50%;
  }

  .countries {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
  }

  .countries-selection {
    border: 1px solid var(--primary-color);
    width: 134px;
    border-radius: 5px;
    height: 47px;
    text-align: center;
    margin: auto 0;
    font-family: "Roboto-Medium";
    font-size: 18px;
  }
}

@media only screen and (min-width: 900px) {
  .next-btn {
    width: 296px;
    height: 54px;
    border-radius: 20px;
  }
}

/* End Travel details */

/* Quotes styles */
/* Quote card styles */
/* dialog */

.l-dialog-container {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 328px;
  background-color: "#F6F8FB" !important;
}

.confirmation-details {
  width: 351px;
  height: 403px;
  background-color: #7f7f7f;
  border-radius: 20px;
}

.cancel-icon {
  margin: 1rem;
  color: #ffffff;
  cursor: pointer;
}

.confirmation-icon {
  text-align: center;
}

.confirmation-amount {
  color: #ffffff;
  font-family: "Roboto-Bold";
  font-size: 1rem;
  text-align: center;
  margin-top: 1.5rem;
}

.confirmation-deposit {
  flex-direction: column;
  font-family: "Roboto-Medium";
  font-size: 10px;
  color: #ffffff;
  margin-top: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.confirmation-description {
  width: 90%;
  margin: auto;
  text-align: center;
  height: 83px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 2px 2px 2px 2px #ffffff;
  font-size: 12px;
  font-family: "Roboto-Bold";
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.terms {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-family: "Roboto-Regular";
  color: #ffffff;
}

.terms .terms-condition {
  color: #ffffff;
}

.btn-confirm {
  width: 84.13px;
  height: 27.87px;
  border-radius: 4.21px;
  background-color: var(--primary-color);
  color: #ffffff;
  font-size: 9px;
  font-family: "Roboto-Bold";
}

/* End Dialog */

.basic-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 21px;
}

.title-info {
  justify-content: baseline;
  text-align: left;
}

.title-info h4 {
  font-weight: bold;
  font-size: 16px;
  line-height: 6px;
  color: #222222;
  margin-top: 1rem;
}

.title-info h5 {
  font-weight: bold;
  font-size: 12px;
  line-height: 6px;
  text-align: center;
  color: #242a38;
  text-align: left;
  margin-top: 1rem;
}

.title-info h6 {
  font-weight: bold;
  font-size: 12px;
  line-height: 6px;
  text-align: center;
  color: #242a38;
  text-align: left;
  margin-top: 1rem;
}

.payment-title-quote {
  font-size: 21.33px;
  color: #000000;
  font-family: "Roboto-Bold";
}

.payment-amount {
  color: #000000;
  font-family: "Roboto-Black";
  font-size: 24px;
}

.payment-description {
  font-size: 12px;
  font-family: "Roboto-Medium";
  color: #808080;
}

.payment-description-pdf {
  color: #3dc6dd;
  text-decoration: underline;
  cursor: pointer;
}

svg {
  border: none !important;
}

.payment-tax-information {
  font-size: 12px;
  font-family: "Roboto-Medium";
  color: #000000;
}

.total {
  font-size: 14px;
  font-family: "Roboto-Bold";
  color: #000000;
  margin-bottom: -1.5rem;
  text-decoration: underline;
  cursor: pointer;
}

.bg-green-button {
  color: #ffffff;
  background: var(--primary-color);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 12px;
  font-family: "Roboto-Bold";
  text-align: center;
}

@media only screen and (min-width: 1000px) {
  .title-info h4 {
    font-weight: bold;
    font-size: 22px;
    line-height: 12px;
    color: #222222;
  }

  .title-info h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
    color: #242a38;
    text-align: left;
  }

  .total {
    font-size: 14px;
    color: #000000;
    font-family: "Roboto-Bold";
    margin-bottom: -1.5rem;
    text-decoration: underline;
  }

  .bg-green-button {
    width: 300px;
    font-size: 18px;
    height: 54px;
  }
}

/* End Quote Card Styles */
/* Quotes listing styles*/
.quote-container {
  margin: auto;
  padding: 29px;
  margin-bottom: 20px;
}

.quotes-cont {
  flex-direction: column;
}

.quote-container {
  background: #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 10px;
  width: 100%;
}

.basic-details-mobile {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  color: #737373;
}

.basic-details-mobile p {
  font-weight: bold;
  font-size: 11px;
  line-height: 21px;
  color: #696969;
}

.insurer-logo {
  display: flex;
  align-items: center;
}

.insurer-logo img {
  width: 60px;
}

.addons-container-mobile {
  background: #eff8eb;
  border-radius: 8px;
  padding-left: 12px;
  padding-top: 5px;
}

.additions-title {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 6px;
  color: #222222;
  margin-top: 14px;
}

.addons-container-mobile {
  background: #eff8eb;
  border-radius: 8px;
  padding-left: 12px;
  padding-top: 5px;
}

.desc-mob {
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #808080;
}

.basic-details-mobile {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  color: #737373;
}

.basic-details-mobile p {
  font-weight: bold;
  font-size: 11px;
  line-height: 21px;
  color: #696969;
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}

.covs-lims-txt {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 13px;
  color: #808080;
}

.covs-lims-txt-b {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
}

.bg-wr {
  background: #f1f2f6;
}

/* desktop view Start*/
@media only screen and (min-width: 1100px) {
  .quote-container {
    width: 687px;
  }

  .insurer-logo img {
    width: 78px;
  }

  .addons-container-mobile {
    display: none;
  }

  .additions-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 44px;
    color: #222222;
  }

  .addon-desc {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
  }

  .addons-container-mobile {
    display: none;
  }
}

.pp-container {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.pp-amt {
  margin-left: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

h3 {
  font-family: "Poppins-Bold";
  font-size: 18px;
  color: #222222;
  padding-top: 1rem;
}

.h2 {
  font-family: "Poppins-medium";
  font-size: 14px;
  color: #808080;
}

p {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #808080;
}

.addons-select {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1rem;
}

.term-period {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pp-period-selector {
  width: 315px;
  height: 55px;
}

@media (max-width: 500px) {
  .pp-period-selector {
    width: -webkit-fill-available;
  }
}

.reg-title {
  font-size: 28.27px;
  font-family: "Roboto-Bold";
  color: var(--primary-color);
}

/* EndQuotes Listing styles */
/* End Quotes Styles */

/* Authentication styles */
.auth-login {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.auth-login-description {
  margin-bottom: 1rem;
}

.btn-sign-with-google {
  width: auto;
  height: 54px;
  border-radius: 8px;
  font-family: "Roboto-Regular";
  font-size: 18px;
  margin-bottom: 2rem;
}

.image-or {
  width: 100%;
}

.auth-input-container {
  width: 100%;
}

.auth-input-container input {
  background: #fbfbfb;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 45px;
  padding-top: 25px;
  height: 57px;
  width: 100%;
}

.auth-input-container img {
  margin-bottom: -75px;
  margin-left: 12px;
  position: relative;
  z-index: 9;
}

.login-info {
  color: #000000;
}

.label {
  margin-bottom: -20px;
  margin-left: 12px;
  position: relative;
  z-index: 30;
  font-weight: 500;
  font-size: 10px;
  color: #d0d0d0;
  margin-left: 45px;
}

.bottom-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  margin-top: 1rem;
}

.rem-container {
  display: flex;
}

input[type="checkbox"] {
  width: 13%;
}

.rem-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
}

.sign-up-text {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto-Regular";
  margin-top: 1rem;
}

.sign-up-text span {
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary-color);
  font-family: "Roboto-Bold";
}

.pointer-btn {
  background-color: var(--primary-color);
  color: #ffffff;
  font-size: 1rem;
  font-family: "Roboto-Bold";
  width: 296px;
  height: 54px;
  border-radius: 20px;
  margin-bottom: 2rem;
  outline: none;
  border: none;
}

.error-icn {
  margin-bottom: 0px !important;
}

.forgot-txt {
  font-size: 12px;
  text-decoration: underline;
  color: #000000;
  cursor: pointer;
  font-family: "Roboto-Regular";
}

@media only screen and (min-width: 600px) {
  .auth-input-container {
    width: auto;
  }

  .bottom-text-container {
    width: 30%;
  }

  .btn-sign-with-google {
    width: 382px;
    height: 54px;
  }

  .image-or {
    width: 30%;
  }

  .ent-cont {
    width: 382px;
  }
}

/* End Authentication styles */

/* Prepare Documents styles */
.title {
  text-align: center;
}

.prepare-title,
.prepare-title-2 {
  font-size: 28.27px;
  font-family: "Roboto-Bold";
}

.prepare-title {
  color: var(--primary-color);
  margin-bottom: 5px;
}

.prepare-title-2 {
  color: #000000;
}

.prepare-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 4rem;
}

ul {
  color: #858887;
  font-family: "Roboto-Medium";
  font-size: 1rem;
  line-height: 2rem;
}

/* End Prepare Documents styles */

/* Details of insured styles (KYCs) */
input,
select {
  background: #ffffff;
  border: 1px solid #dadce0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

a {
  text-decoration: none;
}

.shadow-1 {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
}

input:focus {
  border: 0;
  outline: 0;
  border: 1px solid rgba(48, 52, 94, 0.5);
}

select:focus {
  border: 0;
  outline: 0;
  border: 1px solid rgba(48, 52, 94, 0.5);
}

.text-left-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #858887;
}

.ky-input-container input[type="text"],
input[type="date"],
input[type="email"],
input[type="number"] {
  background: #f6f8fb;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 100%;
  height: 47px;
}

.postal-area-container {
  display: flex;
}

.dialog-p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}

.dialog-canc-btn {
  cursor: pointer;
}

#insudocs {
  min-height: 100vh;
}

.reg-title {
  color: #000000;
}

@media only screen and (min-width: 600px) {
  .ky-input-container input[type="text"],
  input[type="date"] {
    width: 340px;
  }

  .ky-inputs-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }

  .ky-inputs-inner-container {
    display: flex;
    justify-content: space-between;
  }

  input[type="email"] {
    width: 95%;
  }
}

.postal-area-container input {
  width: 150px !important;
  margin-right: 20px;
}

.ml-4 {
  margin-left: 10px;
}

.id-uploads-container {
  display: flex;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .ky-inputs-container-insurer {
    width: 80% !important;
  }
}

/* End Details of insured details(KYCs) */

/* Group details styles */
.group-member {
  width: 132px !important;
  height: 116px !important;
}

.group-member-name {
  text-decoration: underline;
  font-size: 12px;
  color: var(--primary-color);
  font-family: "Roboto-Medium";
  cursor: pointer;
}

.group-member-form {
  pointer-events: none;
  opacity: 0.3;
}

.form input[type="text"] {
  width: 30% !important;
}

.group-btn {
  color: var(--primary-color);
  text-decoration: underline;
  font-size: 14px;
  font-family: "Roboto-Medium";
  cursor: pointer !important;
}

.members-container-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(117px, auto)) !important;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}

/* End group details */

/* Beneficiary Details Screen */
.not-sure {
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  font-family: "Roboto-Medium";
}

.nok-location input {
  width: 70px;
  background-color: green;
}

.ky-input-wrapper-other-relationship {
  width: 95.5% !important;
}

.active-location-nok p {
  color: var(--primary-color);
}

.active-location-nok,
.active-postal-nok {
  color: var(--primary-color);
}

.active-postal-nok p {
  color: var(--primary-color);
}

.location-area-container input {
  width: 100% !important;
}

#nokPostalAddress,
#nokPostalCode {
  width: 50% !important;
}

#nokPhoneNo {
  width: 100% !important;
}

@media only screen and (min-width: 600px) {
  .ky-input-wrapper-other-relationship {
    width: 100% !important;
  }

  .nok-location .not-sure {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  .ky-inputs-container-beneficiary {
    width: 100% !important;
  }
}

/* End beneficiary Details Screen */

/* Summary Confirmation Screen */
.confirmation-wapper {
  padding-left: 10px;
  padding-right: 10px;
}

.conf-b-group-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.conf-keys {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #242a38;
}

.cr-line {
  border: 0.2px solid #646c78;
}

.gr-tot {
  background: #d5e9e7;
  border-radius: 2px;
  padding: 8px;
}

.con-amt-bld {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

@media only screen and (min-width: 600px) {
  .confirmation-wapper {
    width: 400px;
    margin: 0 auto;
  }
}

.button-proceed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* End summary Confirmation Screen */

/* Select Payment Option Styles */
.payment-title {
  text-align: center;
}

.payment-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}

.one-time,
.payment-plan {
  width: 138.66px;
  height: 64.56px;
  border: 1px solid var(--primary-color);
  border-radius: 3.67px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "Roboto-Regular";
  cursor: pointer;
}

.payment-plan {
  margin-left: 2rem;
}

.one-time:hover,
.one-time:focus,
.one-time:active {
  background-color: var(--primary-color);
  color: #ffff;
  font-family: "Roboto-Bold";
}

.payment-plan:hover,
.payment-plan:focus,
.payment-plan:active {
  background-color: var(--primary-color);
  color: #ffff;
  font-family: "Roboto-Bold";
}

.option-amount {
  font-weight: bold;
  font-size: 10px;
  font-family: "Roboto-Black";
}

@media only screen and (min-width: 600px) {
  .payment-title {
    text-align: left;
  }

  .payment-options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

/* End select Payment Option styles */

/* Payment methods styles */
/* .payment-method-container {} */

.methods-ctas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 7rem;
}

.visa,
.mpesa {
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .payment-method-container {
    width: 63vw !important;
  }

  .methods-ctas {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .visa {
    margin-left: 2rem;
  }
}

/* End Payment Methods */

/* Visa payments styles */

.input-holder {
  position: relative;
}

.visa-text-label {
  transform: scale(0.75) translateY(-39px) translateX(-5px);
  position: absolute;
  font-size: 18px;
  top: 20px;
  left: 3px;
  padding: 0 8px;
  background-color: #ffff;
  border-radius: 4px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  color: #000000;
}

.input[type="text"] {
  background-color: transparent !important;
  border: 1px solid #000000 !important;
}

/* .input-holder input::placeholder {} */
@media (max-width: 1120px) {
  .card-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

/* End Visa payments styles */

/* Mpesa Payments Styles */
.paym-text {
  font-size: 28px;
  color: #000000;
  font-family: "Roboto-Regular";
}

.paym-text-amount {
  font-family: "Roboto-Bold";
  color: var(--primary-color);
}

.paym-text-sm {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.pym-p {
  font-size: 16px;
  line-height: 24px;
  color: #858887;
  font-family: "Roboto-Medium";
}

.py-input-container input[type="text"] {
  background: #f6f8fb;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 100%;
  height: 47px;
  padding-left: 50px;
  z-index: 0;
}

.kenyan-flag {
  position: absolute;
  margin-top: 17px;
  margin-left: 17px;
  z-index: 1000;
}

.text-bold {
  font-weight: 700;
}

.red-paym-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: #ff5364;
}

.p-alt-tit {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  font-feature-settings: "ss01" on;
  color: #000000;
}

.p-alt-box {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #635f5f;
  border: 0.5px dashed #000000;
  border-radius: 5px;
  height: 36px;
  margin-top: 5px;
}

.p-bills-wrapper {
  margin-top: 20px;
}

.onetime-opt {
  width: 123px;
  height: 55px;
  border: 0.5px solid #b9b9b9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.intsls-opt {
  width: 123px;
  height: 55px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  border: 0.5px solid #b9b9b9;
}

.intsls-opt:hover,
.intsls-opt:focus,
.intsls-opt:active {
  background: rgba(64, 96, 175, 0.7);
  color: #ffffff;
}

.onetime-opt:hover,
.onetime-opt:focus,
.onetime-opt:active {
  background: rgba(64, 96, 175, 0.7);
  color: #ffffff;
}

@media only screen and (min-width: 540px) {
  .py-input-container input[type="text"] {
    width: 340px;
  }

  .paym-text-sm {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    color: #000000;
  }

  .paym-text {
    font-style: normal;
    font-weight: 400;
    font-size: 28.2653px;
    line-height: 40px;
    color: #000000;
  }

  .paym-confirmation-wapper {
    width: 60vw;
    margin: 0 auto;
  }

  .p-bills-conainer {
    display: flex;
    justify-content: space-between;
  }

  .p-alt-box {
    width: 263px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-top: 5px;
  }

  .onetime-opt {
    width: 189px;
    height: 88px;
    border: 0.5px solid #b9b9b9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .intsls-opt {
    width: 189px;
    height: 88px;
    /* color: #FFFFFF; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* background: rgba(64, 96, 175, 0.7); */
    margin-left: 20px;
    border: 0.5px solid #b9b9b9;
  }
}

@media (max-width: 500px) {
  .select-buttons {
    display: flex;
    justify-content: space-around;
  }
}

/* End Mpesa Payments styles */

/* Payments styles */
input,
select {
  background: #ffffff;
  border: 1px solid #dadce0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

a {
  text-decoration: none;
}

.shadow-1 {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
}

input:focus {
  border: 0;
  outline: 0;
  border: 1px solid rgba(48, 52, 94, 0.5);
}

select:focus {
  border: 0;
  outline: 0;
  border: 1px solid rgba(48, 52, 94, 0.5);
}

.text-left-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #858887;
}

.ky-input-container input[type="text"],
input[type="date"] {
  background: #f6f8fb;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 100%;
  height: 47px;
}

.postal-area-container {
  display: flex;
}

.dialog-p {
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #858887;
  font-family: "Roboto-Regular";
}

.dialog-proc-btn {
  background: var(--primary-color);
  border-radius: 4.48326px;
  width: 188px;
  height: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 13px;
  cursor: pointer;
  font-family: "Roboto-Medium";
}

.dialog-canc-btn {
  cursor: pointer;
}

#credit-rting-cont {
  min-height: 100vh;
}

.trustgro input[type="checkbox"] {
  width: 13px;
  margin-right: 1rem;
  margin-left: 8px;
  margin-bottom: 65px;
}

@media only screen and (min-width: 600px) {
  .ky-input-container input[type="text"],
  input[type="date"] {
    width: 340px;
  }

  .ky-inputs-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 55vw;
    margin: 0 auto;
  }

  .ky-inputs-inner-container {
    display: flex;
    justify-content: space-between;
  }

  .trustgro input[type="checkbox"] {
    margin-bottom: 20px;
  }
}

.postal-area-container input {
  width: 150px !important;
  margin-right: 20px;
}

.ml-4 {
  margin-left: 10px;
}

.id-uploads-container {
  display: flex;
  cursor: pointer;
}

.terrms-txt {
  font-size: 12px;
  line-height: 18px;
  font-family: "Roboto-Regular";
  color: var(--primary-color);
}

.terrms-txt a {
  text-decoration: underline;
  font-family: "Roboto-Bold";
  color: var(--primary-color);
}

.dialog-p-t {
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #000000;
  font-family: "Roboto-Bold";
}

.confirmation-wapper {
  padding-left: 10px;
  padding-right: 10px;
}

.conf-b-group-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.conf-keys {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #242a38;
}

.cr-line {
  border: 1px dashed #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.gr-tot {
  background: #d5e9e7;
  border-radius: 2px;
  padding: 8px;
}

.con-amt-bld {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.pp-period-selector {
  background: #eeeeee;
  border-radius: 9.184px;
  display: flex;
  margin: 0 auto;
}

.pp-period-selector .pm-per {
  border-radius: 11.48px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
}

.sel-per-cls {
  color: #ffffff;
  background-color: var(--primary-color);
  font-family: "Roboto-Bold";
}

.day-select {
  font-size: small;
  padding: 5px;
  width: 10%;
}

.tiny-blue-line {
  border: 2px solid #c8e8e5;
}

.loan-terms-border {
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 4px;
  padding: 10px;
}

.ln-txt {
  font-size: 13px;
  line-height: 20px;
}

.bold {
  color: #000000;
  font-style: normal;
  font-family: 600;
}

.bld-tit {
  color: #000000;
  font-weight: 700;
}

@media only screen and (min-width: 600px) {
  .confirmation-wapper {
    width: 400px;
    margin: 0 auto;
  }

  /* payment option styles */
  .l-dialog-container-more-information {
    line-height: 3rem !important;
    width: 381px;
    height: 278px;
  }

  /* End payment option styles */
}

.id-number-input {
  cursor: not-allowed;
}

.dialog-p-more-information {
  line-height: 1.2rem !important;
}

/* End Payments styles */

/* List policies styles */
.my-pol-card {
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  padding: 20px;
}

.my-pol-det-container {
  display: flex;
  flex-wrap: wrap;
}

.my-pol-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  color: #808080;
  margin-bottom: 5px;
}

.my-pol-tit {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
}

.my-pol-cta-container {
  display: flex;
  align-items: center;
}

.my-pol-line {
  border: 1px solid var(--primary-color);
}

.my-pol-buy-container {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
  width: 138.88px;
  height: 38.38px;
  font-style: normal;
  font-weight: 400;
  font-size: 11.1663px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  font-family: "Roboto-Regular";
}

.my-pol-claim-container {
  border: 1px solid #bababa;
  border-radius: 5px;
  cursor: pointer;
  width: 138.88px;
  height: 38.38px;
  font-style: normal;
  font-weight: 400;
  font-size: 11.1663px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

.mp-2 {
  padding-right: 4vw;
  margin-bottom: 2vh;
}

.claim-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26.56px;
  background: #d0e8fc;
  border-radius: 13.2787px;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 16px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #3faefe;
}

.bs-link-n {
  margin-right: 20px;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .my-pol-card {
    padding: 40px;
  }
}

.image {
  cursor: pointer;
  /* transition: all; */
}

/* End list policies styles */

/* List claims styles */

.claim-status {
  width: 68px;
  border-radius: 10.62px;
  height: 21.16px;
  border: 1px solid;
  font-family: "Roboto-Bold";
  font-size: 8px;
  text-align: center;
  padding: 4.8px;
  outline: none;
}

.submitted {
  background-color: var(--primary-color);
  border: none;
  color: #ffff;
  text-transform: uppercase;
}

/* End List claims styles */

/* RENEWALS STYLINGS */
.sub-title {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 1rem;
  line-height: 1rem;
}

.amount {
  color: #37d9a8;
  font-size: 20px;
  font-weight: bold;
}

.transactions-header {
  margin: 3rem auto;
  max-width: 520px;
  /* margin: 0 auto; */
}

.transactions-header h2 {
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}

.transactions-listing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transactions-listing-title {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}

.transactions-listing-amount {
  color: #58c53d;
  font-size: 1rem;
  font-weight: bold;
}

@media (min-width: 600px) {
  .payment-information {
    max-width: 400px;
  }
}

/* END RENEWALS STYLINGS */

/* CLAIMS STYLINGS */
/* Baggage claim styles */
.baggage-item {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  width: 132px;
  height: 91px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.item-name {
  color: #979797;
  font-family: "Roboto-Regular";
  font-size: 12px;
  margin: 1rem 0 0.3rem 1rem;
}

.item-price {
  font-family: "Roboto-Regular";
  font-size: 8px;
  color: #979797;
  margin: 0rem 0 0 1rem;
}

.btn-add-item {
  border: 2px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  font-family: "Roboto-Regular";
  font-size: 12px;
  width: 138.88px;
  height: 38.38px;
  border-radius: 4px;
  margin-top: 3rem;
}

.baggage-button {
  width: 100px !important;
}

@media only screen and(min-width: 1190px) {
  .add-claim-item {
    margin-left: 5rem !important;
  }
}

/* End Baggage claim styles */
/* claim confirmationstyles */
.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmation-icon {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.confirmation-title {
  font-family: "Roboto-Regular";
  font-size: 24px;
  color: var(--primary-color);
  margin-bottom: 1rem;
  /* margin-top: 1rem; */
}

.confirmation-content {
  font-size: 14px;
  color: #979797;
  font-family: "Roboto-Regular";
  line-height: 27px;
  text-align: center;
  width: 80%;
  margin-bottom: 1rem;
}

/* claim confirmation styles */
/* END CLAIMS STYLINGS */

/* Share quotes styles */

.share-button {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: transparent;
  border-radius: 4.48px;
  width: 188px;
  height: 38px;
  font-size: 15px;
  font-family: "Roboto-Regular";
  padding: initial;
}
.container-share {
  margin-bottom: 2rem;
}
.bt-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 687px;
  margin: auto;
  margin-bottom: -2rem;
}

.checkbox-data {
  width: 80% !important;
}

.handle-select {
  color: var(--primary-color);
  text-decoration: underline;
  font-size: 16px;
  font-family: "Roboto-Regular";
  cursor: pointer;
}

.cancel-share-quotes-icon {
  position: relative;
  left: 112px;
  margin: 1rem auto;
  cursor: pointer;
}

.share-quote-input {
    width: 90%;
    margin: 1rem auto;
}
.textDialog {
    border-style: dashed;
    border-color: rgb(187, 186, 186);
    border-radius: 0px;
    margin-top: 40px;
    padding: 5px;
    width: fit-content;
    height: auto;
    font-size: 12px;
    margin-bottom: 60px;
    /* background-color: wheat; */
    color: black;
    text-align: center;
  }

@media only screen and (max-width: 570px) {
  .share-button {
    max-width: 150px;
  }
}
/* End share quotes styles */
