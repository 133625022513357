body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(../src/assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(../src/assets//fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Regular'), url(../src/assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'), url(../src/assets/fonts/Roboto-Black.ttf) format('truetype');
}

svg {
  border: 1px solid #000000 !important;
  border-radius: 50%;
  font-size: 1.2rem;
}

.previous {
  font-size: 1rem;
  font-family: "Roboto-Bold";
  color: #000000;
}

.auth-login-description {
  font-size: 28.27px;
  font-family: "Roboto-Bold";
  color: #E55C3E;
  text-align: center;
  margin-bottom: -1rem;
}