.expense-disclaimer {
    width: 345px;
    background-color: #BCF2E2;
    height: 97px;
    border-radius: 13.28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.expense-disclaimer-name {
    font-family: 'Roboto-Bold';
    font-size: 12px;
    color: #000000;
    margin: 1rem 0 0.5rem 1rem;
}

.expense-disclaimer-content {
    font-family: 'Roboto-Regular';
    font-size: 12px;
    margin: 0 0 0.2rem 1rem;
}