.claim-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, auto)) !important;
    grid-row-gap: 0.6rem;
    grid-column-gap: 1rem;
    max-width: 972px;
    margin: 0 auto;
}

.claim-option {
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    .claim-option-btn {
        margin-top: 3rem;
    }
}